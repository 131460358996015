import React, { useState } from 'react'
import styled from 'styled-components'

import Layout from 'components/Layout'

import Colors from 'utils/Colors'
import * as Mixins from 'utils/Mixins'
import * as t from 'utils/Typography'
import { Background } from 'utils/styles'

import { SHOW_ALL_FILTER, usesFilters, usesThings } from '../Data/uses'
import { myContext } from '../themeProvider'

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin};
  padding: 5em 0 20em 0;
  color: ${(props) => (props.theme === 'white' ? Colors.darkM_Background : Colors.white)};
`

const FilterTagList = styled.ul`
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0px;
`

const FilterTag = styled.li`
  cursor: pointer;
  display: inline-block;
  margin-right: 7px;
  margin-bottom: 7px;
  padding: 5px 10px;
  background: ${(props) => {
    if (props.active) {
      return props.theme === 'white' ? '#6c63ff' : '#FF9801'
    }
    return 'rgb(238, 238, 238)'
  }};
  border-radius: 3px;
  color: ${(props) => (props.active ? '#ffffff' : '#000000')};
`

const UsesGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin: 45px 3% 0 3%;
`

const ElementWrapper = styled.div``

const UseElement = styled.a`
  --box-shadow-color-accent: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.4)' : Colors.darkM_Shadow)};
  --box-shadow-color: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.08)' : Colors.darkM_Shadow)};
  --border-bottom-color: ${(props) => (props.theme === 'white' ? 'rgb(255, 255, 255)' : Colors.darkM_Shadow)}

  min-width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 4px 15px 3px var(--box-shadow-color);
  transition: all 0.3s linear 0s;
  text-decoration: none;
  border-bottom: 3px solid var(--border-bottom-color);

  align-items: flex-start;
  cursor: pointer;


  &:hover {
    box-shadow: 0px 4px 15px 3px var(--box-shadow-color-accent);
    transform: scaleX(1.05) scaleY(1.05);
  }
`

const UseIcon = styled.img`
  height: auto;
  width: 25px;
  overflow: hidden;
  margin-right: 15px;
  border-raidus: 3px;
`

const UseTitle = styled.div`
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  padding: 25px;
`

const UseDesciption = styled.div`
  padding: 0 25px 25px;
  text-align: left;
`

function RenderPage(props) {
  const { context } = props
  const [filters] = useState(usesFilters)
  const [selectedFilter, setselectedFilter] = useState(SHOW_ALL_FILTER)
  const { theme, changeTheme } = context

  return (
    <Background theme={theme}>
      <Layout theme={theme} onChangeTheme={changeTheme}>
        <AboveFold theme={theme}>
          <t.H1 green align="center">
            Things I use
          </t.H1>
          <FilterTagList>
            {filters.map((filter) => (
              <FilterTag
                key={filter.key}
                theme={theme}
                onClick={() => setselectedFilter(filter.key)}
                active={selectedFilter === filter.key}>
                {filter.tag}
              </FilterTag>
            ))}
            <UsesGrid>
              {selectedFilter === SHOW_ALL_FILTER
                ? usesThings.map((thing) => (
                    <ElementWrapper key={thing.key}>
                      <UseElement theme={context.theme} onClick={() => window.open(`${thing.link}`, '_blank')}>
                        <UseTitle>
                          <UseIcon src={`${thing.icon}`} alt={`${thing.title}`} />
                          {thing.title}
                        </UseTitle>
                        <UseDesciption>{thing.description}</UseDesciption>
                      </UseElement>
                    </ElementWrapper>
                  ))
                : usesThings
                    .filter((thing) => thing.filter.includes(selectedFilter))
                    .map((thing) => (
                      <ElementWrapper key={thing.key}>
                        <UseElement theme={context.theme} onClick={() => window.open(`${thing.link}`, '_blank')}>
                          <UseTitle>
                            <UseIcon src={`${thing.icon}`} alt={`${thing.title}`} />
                            {thing.title}
                          </UseTitle>
                          <UseDesciption>{thing.description}</UseDesciption>
                        </UseElement>
                      </ElementWrapper>
                    ))}
            </UsesGrid>
          </FilterTagList>
        </AboveFold>
      </Layout>
    </Background>
  )
}

function UsesPage() {
  return <myContext.Consumer>{(context) => <RenderPage context={context} />}</myContext.Consumer>
}

export default UsesPage
